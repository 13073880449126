import api from './api';
import qs from 'qs';

async function createApplication(applicationName, applicationUrl) {
  const res = await api.post(
    '/oauth2/apps/',
    qs.stringify({
      application_name: applicationName,
      application_url: applicationUrl,
    }),
  );
  return res.data;
}

async function getApplicationById(id) {
  const res = await api.get(`/oauth2/apps/${id}`);
  return res.data;
}

async function getApplications() {
  const res = await api.get(`/oauth2/apps`);
  return res.data;
}

async function updateApplication(
  application_id,
  application_name,
  application_url,
  theme,
  policy_url,
  terms_of_service_url,
  is_consent_display,
) {
  const res = await api.put(
    `/oauth2/apps/${application_id}`,
    qs.stringify({
      application_name,
      application_url,
      theme,
      policy_url,
      terms_of_service_url,
      is_consent_display,
    }),
  );
  return res.data;
}

export {
  getApplicationById,
  getApplications,
  createApplication,
  updateApplication,
};
